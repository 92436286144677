
import { useMachine } from "@xstate/vue";
import { appMachine } from "@/machines";
import { defineComponent, provide } from "vue";

export const INJECT_KEYS = {
  APP: Symbol.for("app"),
};

export default defineComponent({
  name: "app",
  setup() {
    const { service } = useMachine(appMachine);
    provide(INJECT_KEYS.APP, service);
  },
});
