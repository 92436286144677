import { createModel } from "xstate/lib/model";
import { Book, QueryParams, References } from "@/types";

const load = () => {
  const promises = [] as Promise<Response>[];
  const ref: References = {
    "rating-periods": [],
    "series-states": [],
    "sort-orders": [],
    "work-forms": [],
    "work-states": [],
    accesses: [],
    downloads: [],
    lengths: [],
    periods: [],
  };

  const savedData = localStorage.getItem("references");
  if (savedData != null) {
    return new Promise((resolve) =>
      resolve(JSON.parse(savedData) as References)
    );
  }

  for (const key in ref) {
    const promise = fetch(`/api-v1/catalog/${key}`, {
      headers: { Authorization: "Bearer guest" },
    });
    promises.push(promise);
    promise
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        ref[key as keyof References] = res;
      });
  }

  return Promise.all(promises).then(() => {
    localStorage.setItem("references", JSON.stringify(ref));
    console.log(ref);
    return ref;
  });
};

export interface Root {
  references: References;
  books: Book[];
  params: QueryParams;
}

export const appModel = createModel(
  {
    ref: {
      references: {} as References,
      books: [],
      params: {},
    } as Root,
  },
  {
    events: {
      SEARCH: () => ({}),
    },
  }
);

export const appMachine = appModel.createMachine({
  id: "app",
  context: appModel.initialContext,
  initial: "idle",
  states: {
    idle: {
      always: "loading",
    },
    loading: {
      invoke: {
        id: "load",
        src: load,
        onDone: {
          target: "ready",
        },
        onError: {
          target: "failure",
        },
      },
    },
    ready: {
      on: {},
    },
    failure: {},
  },
});
